/* stylelint-disable */
@import '../../settings/style/typography';

.description-block {
  position: relative;
  width: 100vw;
  margin-bottom: 100px;

  &__text {
    max-width: 53vw;
    margin-top: 150px;
    margin-bottom: 35px;
    margin-left: 10vw;
    font-family: $text-font-family-offwhite;
    font-size: $font-size--text-large;
    font-weight: 400;
    line-height: 1.5;
    color: #cccccd;
  }

  &__title {
    margin: 0;
    margin-left: 10vw;
    font-family: $title-font-family;
    font-size: $font-size--title;
    font-weight: 700;
    line-height: 1.1;
    text-transform: uppercase;
    color: #fff;

    &_empty {
      color: transparent;
      -webkit-text-stroke: 1px #fff;
    }
  }

  &__link {
    position: relative;
    margin-top: 100px;
    margin-left: calc(10vw - 35px);

    &:hover {
      .link-circle__text {
        transform: rotate(105deg);
      }

      .link-circle {
        fill: #fff;
      }

      #path942,
      #path946 {
        stroke: #000 !important;
      }
    }

    &_text {
      font-family: $text-font-family-offwhite;
      font-size: 5.5px;
      font-weight: 400;
      line-height: 25.44px;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 1px;
      pointer-events: none;
    }

    .link-circle__text {
      transition: all ease-in-out 0.6s;
      transform-origin: center;
      pointer-events: none;
    }

    .link-circle {
      transition: fill ease-in-out 0.6s;
      fill: transparent;
      pointer-events: none;
    }
  }
}
