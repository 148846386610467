@import '../../settings/style/typography';

.start-project {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 75vh !important;

  &__header {
    position: relative;
    left: calc(41.75vw - 5px);
    width: 670px;
    text-decoration: none;
    cursor: none;
  }

  &__title {
    margin: 0;
    font-family: $title-font-family;
    font-size: $font-size--title;
    font-weight: 700;
    line-height: 100px;
    text-transform: uppercase;
    color: #000;
    pointer-events: none;

    @media screen and (max-width: 1215px) {
      letter-spacing: 1px;
    }

    @media screen and (max-width: 1110px) {
      font-size: $font-size--title - 10px;
    }

    &_empty {
      position: relative;
      margin-bottom: 20px;
      color: transparent;
      -webkit-text-stroke: 1px #000;
    }
  }

  .parallax-wrap {
    position: absolute;
    top: -25px;
    left: calc(41.75vw - 150px);
    display: flex;
    justify-content: center;
    align-content: center;
    width: 150px;
    height: 150px;
    cursor: none;
  }
}
