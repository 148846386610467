body {
  position: relative;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  cursor: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Page Loader */
.js .loading::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
}

.js .loading::after {
  content: '';
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100000;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  pointer-events: none;
  border-radius: 50%;
  opacity: 0.4;
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus {
  outline: none;
}

[data-scroll] {
  will-change: transform;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  counter-reset: figure;
}

.item {
  position: relative;
  max-width: 100%;
  will-change: transform;
}

.item__img {
  position: absolute;
  top: calc(-1 * var(--overflow));
  --overflow: 40px;
  width: 100%;
  height: calc(100% + (2 * var(--overflow)));
  object-fit: cover;
  will-change: transform;
}
