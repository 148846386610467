@import '../../settings/style/typography';

.own-project {
  position: relative;

  &__header {
    position: relative;
    z-index: 3;
    padding-top: 200px;
    padding-left: calc(8.75vw - 5px);

    .title {
      margin: 0;
      font-family: $title-font-family;
      font-size: $font-size--title;
      font-weight: bold;
      line-height: 1;
      text-transform: uppercase;
      text-align: left;
      color: #000;

      @media screen and (max-width: 1215px) {
        letter-spacing: 1px;
      }

      @media screen and (max-width: 1110px) {
        font-size: $font-size--title - 10px;
      }

      &_empty {
        color: transparent;
        -webkit-text-stroke: 1px #000;
      }
    }
  }

  &__article {
    position: relative;
    width: 16.5vw;
    height: auto;
    margin-top: 140px;
    margin-right: 16.5vw;
    margin-left: 8.75vw;
    float: left;
  }
 
  &__horizontal-block {
    position: relative;
    width: 33vw;

    &-first {
      margin-top: 140px;
      float: left;

      .own-project__arrow-link {
        position: absolute;
        left: calc(16vw - 119px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 119px;
        height: 119px;
        cursor: none;
      }
    }

    &-third {
      top: -200px;
      left: 8.75vw;

      .own-project__arrow-link {
        position: absolute;
        bottom: 223px;
        left: calc(16vw - 119px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 119px;
        height: 119px;
        cursor: none;
      }

      .link-img {
        position: relative;
        top: -165px;
      }

      .own-project__link {
        top: -165px;
      }
    }

    .horizontal-block {
      &__img {
        width: 100%;
      }
    }
  }

  &__vertical-block {
    position: relative;
    width: 16.5vw;
    float: left;

    &-second {
      margin-top: 750px;

      .own-project__arrow-link {
        position: absolute;
        left: -119px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 119px;
        height: 119px;
        cursor: none;
      }

      .horizontal-block__img {
        width: 100%;
      }
    }
  }

  &__link {
    position: relative;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 16.5vw;
    text-decoration: none;
    cursor: none;

    &_center {
      left: 16.5vw;
    }

    .link__title {
      margin: 50px 0 20px;
      font-family: $title-font-family;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      color: #000;
      pointer-events: none;
    }

    .link__text {
      margin: 0;
      font-family: $text-font-family;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #000;
      pointer-events: none;
    }
  }
}
