@import '../../settings/style/typography';

.live-communications {
  position: relative;
  margin-top: 100px;

  &__title-wrapper {
    position: relative;
    z-index: 9999;
    width: 100vw;
    height: 200px;
    margin-bottom: 75px;

    &:hover {
      .live-communications__title {
        animation-play-state: running;
      }
    }
  }

  &__title {
    position: relative;
    top: 50px;
    left: 0;
    margin: 0;
    font-family: $title-font-family;
    font-size: $font-size--title;
    font-weight: bold;
    line-height: 1.1;
    text-transform: uppercase;
    color: #fff;
    animation: scroll 60s linear 0.15s infinite;
    animation-play-state: paused;
    pointer-events: none;

    &_empty {
      color: transparent;
      -webkit-text-stroke: $font-stroke--white;
    }
  }

  &__section {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .live-communications__wave-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      padding-left: 25px;
    }

    .live-communications__article-wrapper {
      max-width: 770px;
      padding-right: 25px;

      .live-communications__article {
        font-family: $text-font-family-offwhite;
        font-size: $font-size--text-large;
        font-weight: 400;
        line-height: 1.1;
        color: #fff;
      }
    }
  }
}

@keyframes scroll {
  to {
    transform: translate(-300%, 0);
  }
}

