@import '../../settings/style/typography';

.article-block {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 33px 14px 13px;
  background-color: #fff;
  box-sizing: border-box;

  &__text {
    margin: 0;
    font-family: $text-font-family;
    font-size: $font-size--text-medium;
    line-height: 1.67;
    color: #000;

    @media screen and (max-width: 1400px) {
      font-size: $font-size--text-medium - 2px;
    }

    @media screen and (max-width: 1248px) {
      font-size: $font-size--text-medium - 4px;
    }

    @media screen and (max-width: 1124px) {
      font-size: $font-size--text-medium - 6px;
    }
  }
}
