@font-face {
  font-family: 'DrukTextWide';
  src: url('../fonts/DrukWideMedium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DrukTextWideCyr';
  src: url('../fonts/Druk Cyrillic Collection-20200126T143551Z-001/Druk Cyrillic Collection/Druk Text Wide Cyrillic/Druk\ Text\ Wide\ Cyr\ Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BasisGrotesquePro';
  src: url('../fonts/BasisGrotesquePro-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BasisGrotesquePro-offWhite';
  src: url('../fonts/BasisGrotesquePro-OffWhite.ttf');
  font-weight: normal;
  font-style: normal;
}

$title-font-family: 'DrukTextWideCyr';
$text-font-family: 'BasisGrotesquePro';
$text-font-family-offwhite: 'BasisGrotesquePro-OffWhite';

$font-size--title: 80px;
$font-size--text-large: 30px;
$font-size--text-medium: 18px;
$font-size--text-small: 12px;

$font-stroke--white: 0.75px #fff;
$font-stroke--black: 0.75px #000;

