/* stylelint-disable */
@import '../../settings/style/colors';

.follow-us {
  position: relative;
  z-index: 5;
  width: 220px;
  height: 115px;
  margin-top: 33px;
  margin-left: 9%;
  box-sizing: border-box;
  pointer-events: initial;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transform: translateY(20px);

  &_black {
    .follow-us__socials {
      color: $color--black;
    }

    .follow-us__socials li:hover a {
      color: $color--black;
    }

    .follow-us__socials .parallax-element:hover {
      color: $color--black !important;
    }
  }

  &:hover {
    transform: translateY(0);

    .follow-us {
      &__icon {
        transform: scale(0);
        width: 0;
        margin: 0;
        overflow: hidden;
      }

      &__socials li {
        &:nth-child(1) {
          opacity: 1;
          transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s;
          transform: translateY(0);
        }

        &:nth-child(2) {
          opacity: 1;
          transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
          transform: translateY(0);
        }
      }
    }
  }

  &__icon {
    float: left;
    width: 30px;
    height: 40px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 40px;
    text-align: right;
    transform: scale(1);
    background-image: url('../../img/share.svg');
    background-size: 15px 16px;
    background-position-y: 10px;
    background-repeat: no-repeat;
    cursor: none;
    transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    pointer-events: none;
  }

  &__title {
    float: left;
    font-size: 12px;
    font-weight: 600;
    line-height: 40px;
    color: #fff;
    pointer-events: none;
  }

  &__socials {
    position: relative;
    bottom: 0;
    display: inline-block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-top: 0;
    padding: 0;
    line-height: 30px;
    text-align: left;
    transform: translateX(0);
    color: #fff;

    li {
      position: relative;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 15px;
      line-height: 40px;
      list-style: none;
      color: #999;
      float: left;
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      overflow: hidden;

      &:hover {
        a {
          color: #fff;
        }
      }

      div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }


      a {
        position: absolute;
        display: block;
        width: 30px;
        height: 30px;
        font-size: 12px;
        font-weight: 600;
        line-height: 30px;
        text-decoration: none;
        text-align: center;
        color: #999;
        cursor: none;
        outline: none;
        transition: opacity ease-in-out 0.2s;
      }
    }
  }
}
