@import '../../settings/style/typography';

.header {
  &__logo {
    position: fixed;
    top: 42px;
    left: 32px;
    z-index: 999999;
    width: 98px;
    height: 44px;
  }

  &__burger {
    position: fixed;
    top: 12px;
    right: 25px;
    z-index: 999999;
    width: 100px;
    height: 100px;
  }
}
