/* stylelint-disable */
@import '../../settings/style/colors';
@import '../../settings/style/typography';


.cd-cover-layer,
.cd-cover-layer.invisible {
  background-color: transparent;
}

.cd-cover-layer {
  position: fixed;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  z-index: 5000;
  transform: translateX(-50%) translateY(-50%);
  width: 101%;
  height: 101%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s 0s,visibility 0s 0.4s;
}

.page-is-changing .cd-cover-layer {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0.3s,visibility 0s 0;
}

/*--------------------------------------------------
  02. Magic Cursor
---------------------------------------------------*/

main {
  transition: background 0.4s ease-in-out 0s;
}

#magic-cursor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 15px;
  height: 15px;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out 0.5s;
}

.hidden #magic-cursor {
  opacity: 0 !important;
}

#magic-cursor.magic-cursor--white #ball {
  background-color: $color--white;
  background-image: url('../../img/arrow--white.svg');
  background-size: 0;
  background-repeat: no-repeat;
  border: 2px solid $color--white;
}

#magic-cursor.magic-cursor--black #ball {
  background-color: $color--black;
  background-image: url('../../img/arrow-next.svg');
  background-size: 0;
  background-repeat: no-repeat;
  border: 2px solid $color--black;
}

#ball {
  position: fixed;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  pointer-events: none;
  opacity: 1;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 4px solid #fff;
    border-radius: 50%;
    opacity: 0;
  }

  #ball-see-case {
    position: absolute;
    top: '38%';
    left: '13%';
    font-family: $title-font-family;
    font-size: $font-size--text-small;
    color: $color--white;
    opacity: 0;
  }

  #show-reel {
    position: absolute;
    top: '31%';
    left: '15%';
    font-family: $title-font-family;
    font-size: $font-size--text-small;
    text-transform: uppercase;
    color: $color--black;
    opacity: 0;
  }
}

@keyframes circle {
  12.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 100% 0, 50% 50%, 50% 50%);
  }

  25% {
    clip-path: polygon(50% 0%, 100% 0, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 100% 50%, 50% 50%, 50% 50%);
  }

  37.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 50%, 50% 50%);
  }

  50% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 50%, 50% 50%);
  }

  62.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 100%, 0 100%, 50% 50%, 50% 50%);
  }

  75% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 50%, 0 50%, 50% 50%, 50% 50%);
  }

  87.5% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 0 0, 50% 50%, 50% 50%);
  }

  100% {
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 100%, 0 0, 50% 0, 50% 50%, 50% 50%);
  }
}

#ball-loader {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid $color--white;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-radius: 50px;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-9px, -9px) rotate(0deg);
  animation: rotating 0.8s ease-in-out infinite;
  transition: opacity 0.2s ease-in-out 0s;
}

.show-loader #ball-loader {
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0s;
}

@keyframes rotating {
  0% {
    transform: translate(-7px, -7px) rotate(0deg);
  }

  95% {
    transform: translate(-7px, -7px) rotate(350deg);
  }

  100% {
    transform: translate(-7px, -7px) rotate(360deg);
  }
}
