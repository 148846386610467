/* stylelint-disable */
@import '../../settings/style/typography';

.communications-block {
  position: relative;

  &__title {
    position: relative;
    z-index: 3;
    margin-top: 150px;
    margin-left: 8.75vw;
    pointer-events: none;

    &_left {
      margin-right: calc(8.75vw - 5px);
      margin-left: auto;
      text-align: right !important;

      .count-project {
        margin-right: 5px;
      }

      .title {
        text-align: right !important;
  
        &_empty {
          text-align: right !important;
        }
      }
    }

    .count-project {
      position: relative;
      top: 5px;
      font-family: $title-font-family;
      font-size: $font-size--text-medium;
      font-weight: 500;
      line-height: 1;
      text-align: left;
      color: #000;
    }

    .title {
      margin: 0;
      margin-left: -8px;
      font-family: $title-font-family;
      font-size: $font-size--title;
      font-weight: bold;
      line-height: 1;
      text-transform: uppercase;
      text-align: left;
      color: #000;
      letter-spacing: 3px;
      pointer-events: none;

      @media screen and (max-width: 1215px) {
        letter-spacing: 1px;
      }

      @media screen and (max-width: 1110px) {
        font-size: $font-size--title - 10px;
      }

      &_empty {
        margin-bottom: 10px;
        color: transparent;
        -webkit-text-stroke: 1px #000;
        pointer-events: none;
      }
    }
  }

  &__article {
    position: relative;
    width: 16.5vw;
    height: auto;
    margin-left: 8.75vw;

    &_right {
      right: 8.75vw;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .communications-block__project-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-top: 75px;
  }

  .communications-block__project-wrapper_left {
    .communications-block__link-img {
      margin-right: auto;
      margin-left: 0;
    }

    .communications-block__link-wrapper_right {
      left: -65px;
    }
  }

  .communications-block__link-img {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-left: auto;
  }

  &__link-wrapper {
    transform: rotate(90deg) translate(calc(-50% + 16px), 0);
    position: relative;
    left: 40px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .communications-block__link-img {
      position: absolute;
      left: -110px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 80px;
      cursor: none;

      img {
        width: 55px;
        height: 29px;
      }
    }
  }

  &__link {
    white-space: nowrap;
    font-family: $title-font-family;
    font-size: 18px;
    font-weight: 500;
    line-height: 50px;
    text-decoration: none;
    text-transform: capitalize;
    color: #000;
    cursor: none;
  }

  &__project {
    position: relative;
    z-index: 2;
    display: block;
    width: 58.25vw;

    a {
      cursor: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
