@import '../../settings/style/typography';

.slick-wrapper {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  height: 80vh;

  .slick-list {
    position: relative;
    margin-left: auto;
  }

  .slick-slide {
    div {
      outline: none;
    }
  }

  .slick-title {
    position: absolute;
    top: 35vh;
    left: 5%;
    font-family: $title-font-family;
    font-size: $font-size--title;
    font-weight: bold;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    pointer-events: none;

    span {
      color: transparent;
      -webkit-text-stroke: $font-stroke--white;
    }
  }

  .slick-img {
    width: 91%;
    height: 80vh;
    object-fit: cover;
    margin-left: auto;
    outline: none;
  }
}

.slick-dots {
  bottom: -83px;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  background-color: transparent;

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 18px;
    margin: 0 20px;
    padding: 0;
    cursor: none;

    .slick-active {
      position: relative;
      z-index: 1;
      width: 72px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      opacity: 1;
      cursor: none;

      > .slick-dots__paging {
        position: relative;
        z-index: 2;
        width: 72px;
        height: 1px;
        background-color: #fff;
        opacity: 1;
        animation-name: bounce_loadingProgressG;
        animation-duration: 150s;
        animation-timing-function: linear;
        cursor: none;
      }
    }

    li {
      position: relative;
      width: 36px;
      height: 1px;
      background-color: #fff;
      opacity: 0.5;
      cursor: none;
      transition: all ease-in-out 0.3s;

      &:not(:last-child) {
        margin-right: 20px;
      }


      .slick-dots__paging {
        width: 36px;
        height: 1px;
        color: transparent;
        background-color: #fff;
        opacity: 0.5;
        cursor: none;
      }
    }
  }

  &__count {
    font-family: $text-font-family;
    font-size: $font-size--text-small;
    color: #fff;
  }
}

@keyframes bounce_loadingProgressG {
  from {
    width: 0;
  }

  to {
    width: 72px;
  }
}
