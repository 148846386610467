.logo {
  position: relative;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity ease-in-out 0.3s;

    &_visible {
      opacity: 1;
    }

    &_hidden {
      opacity: 0;
    }
  }
}
