@import '../../settings/style/colors';

.burger-menu-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.burger-menu {
  position: relative;
  z-index: 2;
  width: 32px;
  height: 30px;
  margin: 0 auto;
  pointer-events: none;

  &__item {
    position: absolute;
    right: 0;
    width: 100%;
    height: 2px;
    opacity: 1;
    transform: rotate(0deg);
    transition: color ease-in-out 0.3s, background-color 0.05s ease-in-out, transform 0.2s ease-in-out, top 0.2s ease-in-out;

    &:first-child {
      top: 10px;
    }

    &:nth-child(2) {
      top: 20px;
    }

    &_color_white {
      background-color: $color--white;
    }

    &_color_black {
      background-color: $color--black;
    }
  }
}
