@import '../../settings/style/typography';

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 25px);
  height: 30px;
  padding-bottom: 87px;

  &__link {
    position: relative;
    width: auto;
    height: 160px;
    margin-left: 8.75vw;

    .parallax-wrap {
      position: absolute;
      top: 30px;
      left: -100px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      opacity: 1;
      cursor: none;
    }
  }

  &__link-text {
    position: relative;
    top: 65px;
    font-family: $text-font-family;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    color: #000;
  }

  &__follow-us {
    margin-left: 0;

    .follow-us {
      bottom: 16px;
      width: 150px;
      height: 80px;
    
      &__icon {
        font-size: 18px;
        background-image: url('../../img/folow.svg');
      }
    
      &__title {
        font-family: $text-font-family;
        font-size: 18px;
        font-weight: normal;
        color: #000;
      }

      &__socials-item {
        color: rgb(19, 19, 19);
  
        &:hover {
          a {
            color: #000;
          }
        }
      }
    }
  }

  &__copyright {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 350px;
    height: 30px;
    margin-right: calc(8.75vw - 103px);

    &:hover {
      .footer__copyright_wrapper {
        position: relative;
        top: -30px;
        right: -10px;

        .footer__copyright_icon {
          transform: scale(0);
          margin-right: 0;
        }
      }

      .footer__copyright_socials {
        top: -15px;
        opacity: 1;
      }
    }

    &_wrapper {
      position: relative;
      top: 0;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
    }

    &_icon {
      margin-right: 5px;
      font-family: $text-font-family;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #000;
      transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
    }

    &_title {
      font-family: $text-font-family;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #000;
      transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
    }

    &_socials {
      position: relative;
      top: 10px;
      opacity: 0;
      cursor: none;
      transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;

      p {
        margin: 0;
        font-family: $text-font-family;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #000;

        a {
          position: relative;
          margin-left: 5px;
          font-family: $text-font-family;
          font-size: 18px;
          font-weight: 400;
          line-height: 30px;
          text-decoration: none;
          color: #000;
          cursor: none;

          &::before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background: rgba(0, 0, 0, 1);
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.2s ease-out;
          }

          &:hover::before {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}
