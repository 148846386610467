@import '../../../settings/style/typography';

.slick-next {
  position: absolute;
  top: calc(100vh - 236px);
  right: 0;
  z-index: 99999;
  width: 295px;
  height: 471px;
  color: #000;
  background-color: #fff;
  cursor: none;
  transition: all ease-in-out 0.3s;

  &::before {
    content: '';
    position: absolute;
    top: 58px;
    left: 65px;
    width: 65px;
    height: 28px;
    background-image: url('../../../img/arrow-next.svg');
    background-size: 65px 28px;
    opacity: 1;
  }

  &::after {
    content: 'Следующий проект';
    position: absolute;
    top: 94px;
    left: 66px;
    width: 99px;
    height: 35px;
    font-family: $text-font-family;
    font-size: $font-size--text-medium;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    opacity: 1;
  }

  &:hover {
    &::before,
    &::after {
      opacity: 0;
    }

    .slick-next__img_active {
      opacity: 1;
      transition: all ease-in-out 0.6s;
    }
  }

  .slick-next__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
  }
}
