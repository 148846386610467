.container-100vh {
  height: 100vh;
  overflow-x: hidden;
};

.content-bg {
  position: relative;
  width: 100vw;
  height: 100%;
  background-color: #cccccd;
  background-image: url('./img/grid-background.svg');
  background-size: 100vw 100%;

  &-full {
    height: 100%;
    background-image: url('./img/grid-background-full.svg');
    background-size: 100vw 100%;
  }
}
